import { Col,Row } from "antd"
import { FC } from "react"
import { useMediaQuery } from "react-responsive"
import { Card } from "."

import { CardsFullWidth } from "../../section"
import { useMenuTop } from "../../utils"
import "./style.less"

interface Props {
  card: Maybe<CardFragment>
  sectionsCards: Maybe<Maybe<SectionCardsFragment>[]>
  cardsOpenItem: Maybe<Maybe<ListLinksFragment>[]>
}

const MainSectionListOpeningCardCenter: FC<Props> = ({ card, sectionsCards }) => {
  useMenuTop()
  const isSmallerDesktop = useMediaQuery({ query: "(max-width: 1289px)" })
  return (
    <section className={"wrapperMainSection layout"} onClick={e => e.stopPropagation()}>
      <Card card={card} />
      {!!sectionsCards?.length && (
        <div className={"wrapper"}>
          <Row justify={`${isSmallerDesktop?"space-around": "space-between"}`} gutter={[20, 20]} style={{margin: 0}}>
            <Col>
              <CardsFullWidth cards={sectionsCards[0]} titleLink />
            </Col>
            <Col>
              <CardsFullWidth cards={sectionsCards[1]} titleLink />
            </Col>
            <Col>
              <CardsFullWidth cards={sectionsCards[2]} titleLink />
            </Col>
            {isSmallerDesktop && (
              <Col>
                <div className={"cardsRight"}>
                  <CardsFullWidth cards={sectionsCards[3]} titleLink />
                  <CardsFullWidth cards={sectionsCards[4]} titleLink />
                </div>
              </Col>
            )}
          </Row>
        </div>
      )}
    </section>
  )
}

export { MainSectionListOpeningCardCenter }
