import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from "react"
import { LinkProps, MetaProps } from "react-helmet-async"

import { useWebsite } from "../../pages"

const baseLinks: LinkProps[] = [
  { href: "https://bndigital.co", rel: "canonical" },
  { href: "/favicon.ico", rel: "icon", type: "image/x-icon" },
  { href: "/favicon.ico", rel: "icon", type: "image/x-icon" },
  { href: "/images/preview/apple-touch-icon.png", sizes: "180x180", rel: "apple-touch-icon" },
  { href: "/images/preview/apple-touch-icon.png", rel: "apple-touch-icon-precomposed" },
  { color: "#5bbad5", href: "/images/preview/safari-pinned-tab.svg", rel: "mask-icon" },
  { href: "/images/preview/favicon-16x16.png", type: "image/x-icon", rel: "shortcut icon" },
]
const baseMeta: MetaProps[] = [
  { content: "/images/preview/apple-touch-icon.png", name: "msapplication-TileImage" },
  { content: "#da532c", name: "msapplication-TileColor" },
  { content: "#ffffff", name: "theme-color" },
  { content: "website", property: "og:type" },
  { content: "en_US", property: "og:locale" },
  { content: "1024", property: "og:image:width" },
  { content: "562", property: "og:image:height" },
  { content: "XcelPros", property: "og:site_name" },
  { content: "XcelPros Images preview", property: "og:image:alt" },
  { content: "https://bndigital.co", property: "og:url" },
  { content: "/images/preview/logo.jpeg", property: "og:image:secure_url" },
  { content: "/images/preview/logo.jpeg", name: "image", property: "og:image" },
  { content: "summary_large_image", name: "twitter:card" },
  { content: "XcelPros", name: "twitter:site" },
  { content: "XcelPros", name: "twitter:creator" },
  { content: "/images/preview/logo.jpeg", name: "twitter:image" },
  { content: "XcelPros | The full-service partner for digital solutions", name: "twitter:title" },
  {
    content:
      "We help businesses to grow and succeed by unleashing the power of design and technology. We excel in fintech, real estate, and healthcare industries.",
    name: "twitter:description",
  },
]
const dynamicMetaTemplate = {
  id: "0",
  keywords: "",
  metaTitle: "XcelPros",
  metaDescription:
    "Helping businesses to optimize business processes with an extremely hands-on goal-oriented approach. We excel in the fintech, real estate, and healthcare industries",
  metaImage: null,
  structuredData: null,
  metaRobots: "",
  metaViewport: "width=device-width, initial-scale=1",
  canonicalURL: "",
}

const fullMetaTemplate = {
  baseMeta: baseMeta,
  baseLinks: baseLinks,
  dynamicMeta: dynamicMetaTemplate,
}

const MetaContext = createContext(fullMetaTemplate)

const MetaProvider: FC<PropsWithChildren> = ({ children }) => {
  const seo = useWebsite()
  const [meta, setMeta] = useState(fullMetaTemplate)
  useEffect(() => {
    if (seo) {
      setMeta(prevState => ({ ...prevState, dynamicMeta: { ...seo, ...prevState.dynamicMeta } }))
    }
  }, [seo])

  return <MetaContext.Provider value={meta}>{children}</MetaContext.Provider>
}

export const useMetaProvider: () => {
  baseMeta: MetaProps[]
  baseLinks: LinkProps[]
  dynamicMeta: {
    id: string
    keywords: string
    metaTitle: string
    metaDescription: string
    metaImage: null
    structuredData: null
    metaRobots: string
    metaViewport: string
    canonicalURL: string
  }
} = () => useContext(MetaContext)

export { MetaProvider }
