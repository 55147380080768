import { MenuProps } from "antd"
import { ItemType } from "antd/es/menu/hooks/useItems"
import React, { Dispatch, FC, SetStateAction } from "react"
import { NavLink } from "react-router-dom"
import { homePageLink } from "../../constants"
import { useGeneralContext } from "../../context"
import { ImgLinkHeader } from "../../ui/ImgLinkHeader"
import { Link } from "../../ui/Link"
import { Card } from "./Card"

type MenuItem = Required<MenuProps>["items"][number]
export const getItem = (
  label: React.ReactNode,
  key: Maybe<React.Key>,
  children?: MenuItem[],
  type?: "group" | null,
  icon?: React.ReactNode
): MenuItem => {
  return { key, children, label, icon, type } as MenuItem
}
const link = (title: Maybe<LinkFragment>) =>
  title?.title && (
    <NavLink to={title?.url ?? ""} className={"headerMenu avgTextSm underlineLink"}>
      <span>{title?.title}</span>
    </NavLink>
  )

const LinkMainMenu: FC<{ title: Maybe<LinkFragment> }> = ({ title }) => {
  const { setOpenKeys } = useGeneralContext()
  return (
    title?.title && (
      <NavLink
        to={title?.url ?? ""}
        className={"headerMenu avgTextSm underlineLink"}
        onClick={() => setOpenKeys([title?.url ?? ""])}
        onMouseEnter={() => setOpenKeys([])}
      >
        <span>{title?.title}</span>
      </NavLink>
    )
  )
}
const linkBurger = (title: Maybe<LinkFragment>) => <Link link={title} className={"headerTitle"} />

const addCard = (item: Maybe<SectionCardsFragment>, setOpenKeysBurger: Dispatch<SetStateAction<string[]>>, notFileLink?:boolean) =>
  item?.cards?.length
    ? getItem(
        <Link
          link={{ url: item?.sup, title: item?.title }}
          className={`headerSubtitle ${!item?.sup || (item?.sup === homePageLink && "emptyLink")}`}
        />,
        item?.title,
        [
          ...item.cards.map(card =>
            getItem(
              <Link
                link={{ url: card?.url, title: card?.title, file: notFileLink? null: card?.file }}
                onClick={() => setOpenKeysBurger([])}
                className={
                  (!card?.url && !card?.file?.data?.attributes?.url) || card?.url === homePageLink ? "emptyLink" : "test"
                }
              />,
              ` ${item?.title} ${card?.title}`
            )
          ),
        ]
      )
    : getItem(
        <Link
          link={{ url: item?.sup, title: item?.title }}
          className={`headerSubtitle ${!item?.sup || (item?.sup === homePageLink && "emptyLink")}`}
        />,
        item?.title
      )

const addCards = (cards: Maybe<SectionCardsFragment>[], setOpenKeysBurger: Dispatch<SetStateAction<string[]>>) =>
  cards?.map(item => addCard(item, setOpenKeysBurger))
const addImgCards = (
  cards: Maybe<SectionCardsFragment>[],
  setOpenKeysBurger: Dispatch<SetStateAction<string[]>>,
  setOpenPopUp: Dispatch<SetStateAction<boolean>>
) =>
  cards?.map(item =>
    item?.cards?.length
      ? getItem(
          <Link
            link={{ url: item?.sup, title: item?.title }}
            className={`headerSubtitle ${!item?.sup || (item?.sup === homePageLink && "emptyLink")}`}
          />,
          item?.title,
          [
            ...item.cards.map(card =>
              getItem(
                card?.file?.data?.attributes?.url ? (
                  !localStorage.getItem("gated") && card?.isOpen ? (
                    <span className={"pseudoLink imgList"} onClick={() => setOpenPopUp(true)}>
                      <ImgLinkHeader img={card?.icon} title={card?.title} />
                    </span>
                  ) : (
                    <NavLink className={"imgList"} to={card?.file?.data?.attributes?.url}>
                      <ImgLinkHeader img={card?.icon} title={card?.title} />
                    </NavLink>
                  )
                ) : (
                  <NavLink
                    to={card?.url === homePageLink ? "" : card?.url ?? ""}
                    onClick={() => setOpenKeysBurger([])}
                    className={`imgList ${!card?.url || (card?.url === homePageLink && "notLink")}`}
                  >
                    <ImgLinkHeader img={card?.icon} title={card?.title} />
                  </NavLink>
                ),
                ` ${item?.title} ${card?.title}`
              )
            ),
          ]
        )
      : getItem(<React.Fragment />, "")
  )
export const addItemMenu = (title: Maybe<LinkFragment>, subMenu?: React.ReactNode): ItemType =>
  subMenu
    ? getItem(<LinkMainMenu title={title} />, title?.title, [getItem(subMenu, `${title?.title}-sub`)])
    : getItem(link(title), title?.title)

export const addItemMenuBurger = (
  title: Maybe<LinkFragment>,
  card: Maybe<CardFragment>,
  cards: Maybe<Maybe<SectionCardsFragment>[]>,
  setOpenKeysBurger: Dispatch<SetStateAction<string[]>>
): ItemType =>
  card && !!cards?.length
    ? getItem(linkBurger(title), title?.title, [
        getItem(<Card card={card} staticImg setOpenKeysBurger={setOpenKeysBurger} />, card?.title),
        ...addCards(cards, setOpenKeysBurger),
      ])
    : getItem(<Link onClick={() => setOpenKeysBurger([])} link={title} className={"headerTitle"} />, title?.title)

export const addItemCardMenuBurger = (
  title: Maybe<LinkFragment>,
  cards: Maybe<SectionCardsFragment>[],
  linksCard: Maybe<SectionCardsFragment>,
  setOpenKeysBurger: Dispatch<SetStateAction<string[]>>,
  setOpenPopUp: Dispatch<SetStateAction<boolean>>
): ItemType =>
  getItem(<Link link={title} className={"headerTitle"} />, title?.title, [
    ...addImgCards(cards, setOpenKeysBurger, setOpenPopUp),
    addCard(linksCard, setOpenKeysBurger, true),
  ])

export const addSubMenu = (
  subMenuInfo: Maybe<ListLinksFragment>[],
  setOpenKeysBurger: Dispatch<SetStateAction<string[]>>
): (false | ItemType)[] =>
  subMenuInfo.map(
    (item, index) =>
      !!item?.links?.length &&
      getItem(<span className={"headerSubtitle sub"}>{item?.title}</span>, `${item?.title}Sub`, [
        ...item.links.map(link =>
          getItem(<Link link={link} onClick={() => setOpenKeysBurger([])} />, `${item?.title} ${link?.title} ${index}`)
        ),
      ])
  )
