import { Layout as BaseLayout } from "antd"
import { FC,Suspense,useEffect,useRef,useState } from "react"
import { Outlet,useLocation } from "react-router-dom"
import { Footer,Header } from "."
import {
useDarkHeaderThemePathQuery,
useLightHeaderThemePageQuery,
usePageQuery,
useTrendingBlogPostCardQuery,
} from "../../graphql"
import { ContentProps } from "../constants"
import { useGeneralContext } from "../context"
import { PopUpGated } from "../section/popUpGated"
import { Chat } from "../ui/Chat"
import { Loader } from "../ui/Loader"
import { filterByPathname,getConditionIsDarkTheme } from "../utils"
import "./Layout.less"

const Layout: FC = () => {
  const { pathname } = useLocation()
  const [data, setData] = useState<ContentProps | null>(null)
  const { data: dataPage, loading } = usePageQuery()
  const { setRefLoading, setLoading, setTrendingBlogPost, contextHolder } = useGeneralContext()
  const { data: dataDarkHeaderThemePath, loading: loadingDarkHeaderThemePath } = useDarkHeaderThemePathQuery()
  const { data: dataLightHeaderThemePage, loading: loadingLightHeaderThemePage } = useLightHeaderThemePageQuery()
  const { data: dataTrendingBlogPost, loading: loadingTrendingBlogPost } = useTrendingBlogPostCardQuery()

  const conditionIsDarkTheme = getConditionIsDarkTheme(
    pathname,
    dataDarkHeaderThemePath?.darkHeaderThemePaths?.data?.map(elem => elem?.attributes?.key),
    dataLightHeaderThemePage?.lightHeaderThemePages?.data?.map(elem => elem?.attributes?.key)
  )
  const [isDarkTheme, setIsDarkTheme] = useState(conditionIsDarkTheme)
  useEffect(() => {
    setIsDarkTheme(conditionIsDarkTheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, loadingDarkHeaderThemePath, loadingLightHeaderThemePage])

  useEffect(() => {
    window.scrollTo(0, 0)
    setData(
      pathname && dataPage?.page?.data?.attributes?.content
        ? filterByPathname(pathname, dataPage?.page?.data.attributes?.content as ContentProps[])
        : null
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, loading])
  const refLoading = useRef(null)
  useEffect(() => {
    setLoading(loading)
    setRefLoading(refLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    if (!loadingTrendingBlogPost) {
      setTrendingBlogPost(dataTrendingBlogPost?.blogPosts?.data?.map(elem => ({ isOpen: false, ...elem?.attributes })))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingTrendingBlogPost])

  return (
    <BaseLayout className={"layout"}>
      <Header isDarkTheme={!isDarkTheme} />
      {contextHolder}
      <PopUpGated />
      <Suspense fallback={<Loader loading={loading} />}>
        <div className={"wrapperMainContent"}>
          <Outlet context={data} />
        </div>
        <Chat isDarkTheme={isDarkTheme} />
         <Footer />
      </Suspense>
    </BaseLayout>
  )
}

export { Layout }
