import { Menu, MenuProps } from "antd"
import { FC, useEffect, useState } from "react"
import { Logo, addItemCardMenuBurger, addItemMenuBurger, addSubMenu, getItem } from "."
import { useHeaderQuery } from "../../../graphql"
import { useGeneralContext } from "../../context"
import { useOutsideClick } from "../../utils"
import "./style.less"

const TabletMenu: FC<{pathname: string}> = ({pathname}) => {
  const { data: headerData, loading } = useHeaderQuery()
  const data = headerData?.header?.data?.attributes
  const { setOpenPopUp } = useGeneralContext()
  const ref = useOutsideClick(() => setOpenKeysBurger([]))
  const [openKeysBurger, setOpenKeysBurger] = useState<string[]>([])

  useEffect(() => {
    setOpenKeysBurger([])
  }, [pathname])

  if (loading) {
    return null
  }
  const menu = [
    data?.firstMenuTitle?.title,
    data?.secondMenuTitle?.title,
    data?.whyXcelPros?.title,
    data?.thirdMenuTitle?.title,
    data?.fourthMenuTitle?.title,
    data?.fifthMenuTitle?.title,
  ]
  const subMenu: Maybe<string>[] = []
  const subSubMenu: Maybe<string>[] = []
  const itemsBurger: any[] = []
  const onOpenChangeBurger: MenuProps["onOpenChange"] = keys => {
    const lastKey = keys[keys.length - 1]
    if (lastKey && menu.indexOf(lastKey) === -1) {
      subMenu.includes(lastKey)
        ? setOpenKeysBurger([keys[0], keys[1], lastKey])
        : subSubMenu.includes(lastKey)
        ? setOpenKeysBurger([keys[0], keys[1], keys[2], lastKey])
        : setOpenKeysBurger(keys)
    } else {
      !keys.length ? setOpenKeysBurger([]) : setOpenKeysBurger(lastKey ? [keys[0], lastKey] : [keys[0]])
    }
  }

  data?.firstMenuSectionsCards?.length && subMenu.push(...data.firstMenuSectionsCards.map(elem => elem?.title))
  data?.secondMenuSectionsCards?.length && subMenu.push(...data.secondMenuSectionsCards.map(elem => elem?.title))
  data?.thirdMenuSectionsCards?.length && subMenu.push(...data.thirdMenuSectionsCards.map(elem => elem?.title))
  data?.fourthMenuSectionsCards?.length && subMenu.push(...data.fourthMenuSectionsCards.map(elem => elem?.title))
  data?.fourthMenuSectionLinks?.title && subMenu.push(data.fourthMenuSectionLinks.title)
  data?.fifthMenuSectionsCards?.length && subMenu.push(...data.fifthMenuSectionsCards.map(elem => elem?.title))
  data?.firstMenuSectionsCardsOpenItem?.length &&
    subSubMenu.push(...data.firstMenuSectionsCardsOpenItem.map(elem => `${elem?.title}Sub`))
  data?.secondMenuSectionsCardsOpenItem?.length &&
    subSubMenu.push(...data.secondMenuSectionsCardsOpenItem.map(elem => `${elem?.title}Sub`))

  data?.firstMenuTitle &&
    data?.firstMenuCard &&
    data?.firstMenuSectionsCards &&
    itemsBurger?.push(
      addItemMenuBurger(data.firstMenuTitle, data.firstMenuCard, data.firstMenuSectionsCards, setOpenKeysBurger)
    )

  data?.secondMenuTitle &&
    data?.secondMenuCard &&
    data?.secondMenuSectionsCards &&
    itemsBurger?.push(
      addItemMenuBurger(data.secondMenuTitle, data.secondMenuCard, data.secondMenuSectionsCards, setOpenKeysBurger)
    )

  data?.whyXcelPros && itemsBurger?.push(addItemMenuBurger(data.whyXcelPros, null, null, setOpenKeysBurger))

  data?.thirdMenuTitle &&
    data?.thirdMenuCard &&
    data?.thirdMenuSectionsCards &&
    itemsBurger?.push(
      addItemMenuBurger(data.thirdMenuTitle, data.thirdMenuCard, data.thirdMenuSectionsCards, setOpenKeysBurger)
    )

  data?.fourthMenuTitle &&
    data?.fourthMenuSectionLinks &&
    data?.fourthMenuSectionsCards &&
    itemsBurger?.push(
      addItemCardMenuBurger(
        data.fourthMenuTitle,
        data.fourthMenuSectionsCards,
        data.fourthMenuSectionLinks,
        setOpenKeysBurger,
        setOpenPopUp
      )
    )

  data?.fifthMenuTitle && itemsBurger?.push(addItemMenuBurger(data.fifthMenuTitle, null, null, setOpenKeysBurger))
  //the section that was in the initial design, maybe we will use it in the future
  // data?.fifthMenuTitle &&
  //   data?.fifthMenuCard &&
  //   data?.fifthMenuSectionsCards &&
  //   itemsBurger?.push(addItemMenuBurger(data.fifthMenuTitle, data.fifthMenuCard, data.fifthMenuSectionsCards))

  if (itemsBurger?.length) {
    itemsBurger[0].children[2].children =
      !!data?.firstMenuSectionsCardsOpenItem?.length &&
      addSubMenu(data.firstMenuSectionsCardsOpenItem, setOpenKeysBurger)
    itemsBurger[1].children[2].children =
      !!data?.secondMenuSectionsCardsOpenItem?.length &&
      addSubMenu(data.secondMenuSectionsCardsOpenItem, setOpenKeysBurger)
  }

  return (
    <div className={"container mobileMenu"}>
      <div className={"borderWrapper"}>
        <Logo />
        <div ref={ref}>
          <Menu
            mode={"inline"}
            openKeys={openKeysBurger}
            triggerSubMenuAction={"click"}
            onOpenChange={onOpenChangeBurger}
            items={[
              getItem(
                "",
                "sub1",
                itemsBurger,
                null,
                <div className={"burgerMenu"}>
                  <span />
                  <span />
                  <span />
                </div>
              ),
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export { TabletMenu }
