import { FC } from "react"
import { NavLink } from "react-router-dom"
import { useHeaderQuery } from "../../../graphql"
import { pathSearch } from "../../constants"
import { SvgIcon } from "../../ui/SvgIcon"
import "./style.less"

const AuthorizationLinks: FC<{ isTablet: boolean; isDarkTheme: boolean; isWhiteHeader: boolean }> = ({
  isTablet,
  isDarkTheme,
  isWhiteHeader,
}) => {
  const { data: headerData } = useHeaderQuery()
  const data = headerData?.header?.data?.attributes
  return (
    data?.authorizationLinks?.length && (
      <div
        className={`wrapperAuthorization`}
      >
        <div className={"container"}>
          <div className={"top-header-cta"}>
          <div className={"left-header-cta"}>
            <a href={""} className={"link listItemMedium underlineLink"}>
              Connect to webinar
            </a>
          </div>
          <div className={"right-header-cta"}>
            <a href={""} className={"link listItemMedium underlineLink"}>
              Channel Partners
            </a>
            <a href={""} className={"link listItemMedium underlineLink"}>
              Work with us
            </a>
            <a href={"/events"} className={"link listItemMedium underlineLink"}>
              <SvgIcon type={"Calendar"} style={{ marginRight: 8, transform: "translateY(3px)" }} />
              <span>Events</span>
            </a>
            {/* <a href={data?.authorizationLinks?.[0]?.url ?? ""} className={"link listItemMedium underlineLink"}>
            <span>{data?.authorizationLinks?.[0]?.title}</span>
          </a> */}
            <a href={"/schedule-meeting"} className={"link listItemMedium underlineLink"}>
              <span>Schedule a Meeting</span>
              {/*<SvgIcon type={"Calendar"} className={"calendarIcon"} style={{stroke: '#2e2e3a'}}/>*/}
            </a>
          </div>
          </div>

          {isTablet && (
            <NavLink to={`/${pathSearch}`} className={"link linkIcon"}>
              <SvgIcon type={"Search"} className={"icon"} />
            </NavLink>
          )}
        </div>
      </div>
    )
  )
}

export { AuthorizationLinks }
