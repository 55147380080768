import { Modal } from "antd"
import { Form, Formik } from "formik"
import { FC } from "react"
import { useMediaQuery } from "react-responsive"
import { useCreateResourceAccessUserMutation } from "../../../graphql"
import { emailSuccessMessage, isTabletValue } from "../../constants"
import { useGeneralContext } from "../../context"
import "../../layout/Layout.less"
import { Button, Input } from "../../ui/Form"
import { ValuesEmail, emailSchema, initialStateEmail } from "../../utils"
import { handleDownload } from "../../utils/functions"
import "./style.less"

const PopUpGated: FC = () => {
  const isTablet = useMediaQuery(isTabletValue)
  const { openPopUp, setOpenPopUp, messageApi, fileURL } = useGeneralContext()
  const [createResourceAccessUser] = useCreateResourceAccessUserMutation()
  const onSubmit = (values: ValuesEmail) => {
    createResourceAccessUser({ variables: { data: { email: values?.email } } })
      .then(res => console.log(res))
      .catch(err => console.log(err))
    localStorage.setItem("gated", "false")
    messageApi?.open({ type: "success", content: emailSuccessMessage })
    setOpenPopUp(false)
    if (fileURL) {
      handleDownload(fileURL)
    }
  }

  return (
    <Modal
      className={`wrapperPopUpGated layout`}
      centered
      open={openPopUp}
      width={isTablet ? 648 : 808}
      onCancel={() => setOpenPopUp(false)}
    >
      <Formik
        initialValues={initialStateEmail}
        onSubmit={onSubmit}
        validationSchema={emailSchema}
        validateOnBlur={false}
      >
        <Form className={"form"}>
          <h4 className={"title"}>Enter your email address to access website content</h4>
          <Input
            input={{
              isFullWidth: true,
              type: "email",
              name: "email",
              placeholder: "Enter your email address",
            }}
          />
          <Button btn={"Send"} className={"bigLinkThird radius"} />
        </Form>
      </Formik>
    </Modal>
  )
}

export { PopUpGated }
