import React from "react"
import { RxCross1 } from "react-icons/rx"

interface AlertProps {
  message: string
  onClose: () => void
}

const Alert: React.FC<AlertProps> = ({ message, onClose }) => {
  return (
    <div className={"alert alert-dismissible alert-warning"} role={"alert"}>
      <div></div>
      {message}
      <button type={"button"} className={"btn-close"} aria-label={"Close"} onClick={onClose}>
        <RxCross1 />
      </button>
    </div>
  )
}

export default Alert
