import { FC } from "react"
import { NavLink } from "react-router-dom"
import { homePageLink } from "../../constants"
import "./style.less"

const LinkSection: FC<{
  propsLinkSection: [] | { link: LinkFragment; list: Maybe<ListLinksFragment>[] }[]
}> = ({ propsLinkSection }) => {
  return (
    <section className={"wrapperLinkSection"}>
      <ul className={"links"}>
        {propsLinkSection.map((article, index) => (
          <li key={index} className={"linkItem"}>
            {article?.link?.title && (
              <NavLink
                to={article?.link?.url ?? ""}
                style={{ fontSize: 14, color: "#2e2e3a" }}
                className={" lineHeightNormal title underline"}
              >
                <span>{article?.link?.title}</span>
              </NavLink>
            )}
            <ul>
              {article?.list.map((linkSection, index) => (
                <li key={index} className={"subLinks"}>
                  {linkSection?.title && (
                    <NavLink
                      to={linkSection?.url ?? ""}
                      style={{ fontSize: 14, color: "#2e2e3a" }}
                      className={`subTitle lineHeightNormal underline ${
                        !linkSection?.url || (linkSection?.url === homePageLink && "emptyLink")
                      }`}
                    >
                      <span>{linkSection?.title}</span>
                    </NavLink>
                  )}
                  {!!linkSection?.links?.length && (
                    <ul>
                      {linkSection?.links?.map((link, index) => (
                        <li key={index} className={"subSubLink"}>
                          {link?.title && (
                            <NavLink
                              to={link?.url ?? ""}
                              style={{ color: "#7c7c83" }}
                              className={` lineHeightNormal link underline ${
                                !link?.url || (link?.url === homePageLink && "emptyLink")
                              }`}
                            >
                              <span>{link?.title}</span>
                            </NavLink>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </section>
  )
}

export { LinkSection }
