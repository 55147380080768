import { FC } from "react"

const Img: FC<{
  img: Maybe<{ data?: Maybe<{ attributes?: Maybe<{ url: string; alternativeText?: Maybe<string> }> }> }>
  className: string
}> = ({ img, className }) => {
  return img?.data?.attributes && <img className={className} src={img?.data?.attributes?.url} alt={""} />
}

export { Img }
