import { FC } from "react"
import { Card } from "."
import { CardsFullWidth } from "../../section"
import { useMenuTop } from "../../utils"
import "./style.less"

interface Props {
  card: Maybe<CardFragment>
  sectionsCards: Maybe<Maybe<SectionCardsFragment>[]>
}

const MainSectionCardsList: FC<Props> = ({ card, sectionsCards }) => {
  useMenuTop()
  return (
    <section className={"wrapperMainSection wrapperMainSectionCardsList layout"} onClick={e => e.stopPropagation()}>
      <Card card={card} />
      <div className={"wrapper"}>
        <div className={"cards cardsFullWith"}>
          {!!sectionsCards?.length &&
            sectionsCards?.map((cards, index) => <CardsFullWidth cards={cards} key={index} titleLink />)}
        </div>
      </div>
    </section>
  )
}

export { MainSectionCardsList }
