import React, { FC, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { pathProducts, widgetCode } from "../../constants"
import { useZohoChat } from "../../utils"
import { SvgIcon } from "../SvgIcon"
import { ZohoChat } from "./ZohoChat"
import "./style.less"

const Chat: FC<{ isDarkTheme: boolean }> = ({ isDarkTheme }) => {
  const { click, ready } = useZohoChat()
  const { pathname } = useLocation()

  useEffect(() => {
    const timer = setTimeout(() => {
      if (ready) {
        click()
      }
    }, 180000)

    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getProductClass = () => {
    const currentPath = [`/${pathProducts}/icm`, `/${pathProducts}/iqm`, `/${pathProducts}/sop`].find(
      path => path === pathname
    )
    return currentPath ? currentPath.split("/")[2] : ""
  }

  return (
    <React.Fragment>
      <ZohoChat
        url={"https://salesiq.zohopublic.com/widget"}
        widgetCode={widgetCode}
        visible={"hide"}
        position={"bottomright"}
      />
      {ready && (
        <div className={`chatIcon radius ${isDarkTheme ? "darkTheme" : ""} ${getProductClass()}`} onClick={click}>
          <SvgIcon type={"Chat"} />
        </div>
      )}
    </React.Fragment>
  )
}

export { Chat }
