import { FC } from "react"
import { NavLink } from "react-router-dom"
import "./style.less"

const OthersLinksSection: FC<{ links: Maybe<LinkFragment>[] }> = ({ links }) => {
  return (
    <section className={"wrapperOthersLinksSection"}>
      <ul className={"links"}>
        {links?.map(
          (link, index) =>
            link?.title && (
              <li className={"link"} key={index}>
                <NavLink to={link?.url ?? ""} className={"mdText lineHeight114"}>
                  <span
                    // style={{
                    //   color: index < 3 ? "#3649FF" : "#2E2E3A",
                    //   textDecoration: index < 3 ? "underline" : "none",
                    // }}
                  >
                    {link?.title}
                  </span>
                </NavLink>
              </li>
            )
        )}
      </ul>
    </section>
  )
}

export { OthersLinksSection }
