import { Col, Row } from "antd"
import { Form, Formik, FormikHelpers } from "formik"
import { FC, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useCreateFormMutation } from "../../../../graphql"
import { requestSuccessMessage } from "../../../constants"
import { useGeneralContext } from "../../../context"
import { Button, Input } from "../../../ui/Form"
import { ValuesEmail, emailSchema, initialStateEmail } from "../../../utils"
import "./index.less"

const EmailSubscriptionForm: FC = () => {
  const [createForm] = useCreateFormMutation()
  const [footer] = document.getElementsByClassName("wrapperFooter") // Assuming .form is your form cl
  const form = footer?.querySelector(".footer-form") as HTMLFormElement | null
  const { messageApi } = useGeneralContext()
  const { pathname } = useLocation()
  useEffect(() => {
    if (form) {
      form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const onSubmitEmail = (values: ValuesEmail, formikBag: FormikHelpers<ValuesEmail>): undefined => {
    createForm({ variables: { data: { email: values?.email } } })
      .then(res => console.log(res))
      .catch(err => console.log(err))
    formikBag.resetForm()
    messageApi?.open({ type: "success", content: requestSuccessMessage })
  }

  return (
    <Formik
      initialValues={initialStateEmail}
      onSubmit={onSubmitEmail}
      validationSchema={emailSchema}
      validateOnBlur={false}
    >
      <Form className={"footer-form"} id={"form"}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <div className={"form-header"}>newsletter signup</div>
          </Col>
          <Col span={24}>
            <Input
              input={{
                isFullWidth: true,
                type: "email",
                name: "email",
                placeholder: "Enter your email address",
              }}
            />
          </Col>
          <Col span={24}>
            <Button btn={"Subscribe"} className={"radius"} />
          </Col>
        </Row>
      </Form>
    </Formik>
  )
}

export { EmailSubscriptionForm }
