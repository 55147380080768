import { FC } from "react"
import { Helmet, LinkProps, MetaProps } from "react-helmet-async"

import { useWebsite } from "../../pages"
import { useMetaProvider } from "./MetaContext"

const structuredDataToString = (structuredData: Array<JSON>) => {
  let output = ""

  if (structuredData) {
    structuredData.forEach(value => (output += `${JSON.stringify(value)}, `))
  }

  return output ? `${output.slice(0, -2)}` : ""
}

const Meta: FC<{ data?: Maybe<SeoFragment> }> = ({ data }) => {
  const fullMeta = useMetaProvider()
  const seo = useWebsite()
  const dynamicMeta: Maybe<SeoFragment> = data
    ? { ...fullMeta?.dynamicMeta, ...data }
    : { ...fullMeta?.dynamicMeta, ...seo }

  const links: LinkProps[] = fullMeta.baseLinks
  const meta: MetaProps[] = fullMeta.baseMeta

  if (dynamicMeta?.keywords) {
    meta.push({ name: "keywords", content: dynamicMeta.keywords })
  }

  if (dynamicMeta?.metaTitle) {
    meta.push(
      { name: "title", property: "og:title", content: dynamicMeta.metaTitle },
      {
        name: "twitter:title",
        content: dynamicMeta.metaTitle,
      }
    )
  }

  if (dynamicMeta?.metaDescription) {
    meta.push(
      { name: "description", property: "og:description", content: dynamicMeta.metaDescription },
      { name: "twitter:description", content: dynamicMeta.metaDescription }
    )
  }
  if (dynamicMeta?.metaRobots) {
    meta.push({ name: "robots", content: dynamicMeta.metaRobots })
  }
  if (dynamicMeta?.metaViewport) {
    meta.push({ name: "viewport", content: dynamicMeta.metaViewport })
  }
  if (dynamicMeta?.metaImage) {
    meta.push(
      { name: "image", property: "og:image", content: dynamicMeta?.metaImage?.data?.attributes?.url ?? "" },
      { property: "og:image:width", content: "1280" },
      { property: "og:image:height", content: "739" },
      { name: "twitter:card", content: "summary_large_image" }
    )
  }
  if (dynamicMeta?.canonicalURL) {
    links.push({ href: dynamicMeta?.canonicalURL, rel: "canonical" })
  }
  return (
    <Helmet
      prioritizeSeoTags
      titleTemplate={"%s ⊹ XcelPros"}
      title={dynamicMeta?.metaTitle}
      meta={meta}
      link={links}
      defaultTitle={"XcelPros"}
      script={[
        {
          type: "application/ld+json",
          innerHTML: dynamicMeta?.structuredData
            ? structuredDataToString(dynamicMeta.structuredData as Array<JSON>) ?? ""
            : "",
        },
      ]}
    />
  )
}

export default Meta
