import {
  ContentProps,
  homePageLink,
  pathBlog,
  pathBrochure,
  pathChallenges,
  pathCustomerJourneys,
  pathSlides,
  pathWhitepaper,
} from "../constants"

export const handleDownload = (fileURL: string): undefined => {
  const link = document.createElement("a")
  link.download = ""

  link.href = fileURL
  link.target = "_blank"

  link.click()
}
export const getConditionIsDarkTheme = (
  pathname: string,
  darkHeaderThemePaths: Maybe<Maybe<string>[]>,
  lightHeaderThemePages: Maybe<Maybe<string>[]>
): boolean =>
  !!(darkHeaderThemePaths?.includes(pathname.split(homePageLink)[1]) && !lightHeaderThemePages?.includes(pathname))

export const getNewSubTitle = (text: string): string => {
  const arrText = text.replace(" &", "").trim().split(" ")
  const temp = arrText[1]
  arrText[1] = `${arrText[2]},`
  arrText[2] = temp
  return arrText.join(" ").toString()
}

export function filterByPathname<T extends ContentProps>(pathname = "/", data: (T | null)[] = []): T | null {
  return data.find(it => it?.pathname === pathname || `${it?.pathname}/` === pathname) ?? null
}

export const splitSliceJoinString = (string: string, start: number, deleteCount?: number): string => {
  const splitString = string.split(" ")
  const spliceString = deleteCount ? splitString.splice(start, deleteCount) : splitString.splice(start)
  return spliceString.join(" ")
}

export const getCurrentVisibleSectionData = (visibleSection: [boolean, string][], data: string): string => {
  for (let i = 0; i < visibleSection.length; i++) {
    if (visibleSection[i][0]) {
      if (i === 0) {
        data = visibleSection[i + 1][0] ? visibleSection[i + 1][1] : visibleSection[i][1]
      } else {
        data = visibleSection[i][1]
        for (let k = 0; k < i; k++) {
          if (visibleSection[k][0]) {
            data = visibleSection[k][1]
          }
        }
      }
    }
  }
  return data
}

export const truncateWithEllipsis = (text: string, maxLength: number): string =>
  text.length > maxLength ? text.substring(0, maxLength - 3) + "..." : text

interface Data {
  data: { attributes?: Maybe<BlogPostCardFragment> }[]
}
const generateData = (
  pathPrefix: string,
  type: string,
  data: Maybe<Data>
): Maybe<Maybe<BlogPostCardFragment & { path: string; type: string }>[]> =>
  data?.data?.map(elem => ({
    path: `/${pathPrefix}/`,
    type,
    ...elem?.attributes,
  }))

export const getBlogsData = (
  data: Maybe<Data>
): Maybe<Maybe<BlogPostCardFragment & { path: string; type: string }>[]> => generateData(pathBlog, "Blogs", data)
export const getBrochuresData = (
  data: Maybe<Data>
): Maybe<Maybe<BlogPostCardFragment & { path: string; type: string }>[]> =>
  generateData(pathBrochure, "Brochures", data)
export const getChallengesData = (
  data: Maybe<Data>
): Maybe<Maybe<BlogPostCardFragment & { path: string; type: string }>[]> =>
  generateData(pathChallenges, "Challenges", data)
export const getCustomerJourneysData = (
  data: Maybe<Data>
): Maybe<Maybe<BlogPostCardFragment & { path: string; type: string }>[]> =>
  generateData(pathCustomerJourneys, "Customer Journeys", data)
export const getSlidesData = (
  data: Maybe<Data>
): Maybe<Maybe<BlogPostCardFragment & { path: string; type: string }>[]> => generateData(pathSlides, "Slides", data)
export const getWhitepapersData = (
  data: Maybe<Data>
): Maybe<Maybe<BlogPostCardFragment & { path: string; type: string }>[]> =>
  generateData(pathWhitepaper, "Whitepapers", data)

export const convertNameToPath = (string: string): string => string.toLocaleLowerCase().replace(/\s+/g, "_")

export const convertPathToName = (string: Maybe<string>): Maybe<string> => string?.toUpperCase()?.replace(/_/g, " ")
