import React, { FC } from "react"
import { NavLink } from "react-router-dom"
import { homePageLink } from "../../constants"
import { useGeneralContext } from "../../context"
import "./style.less"
import { HeaderNavs } from "../../layout/headerSection/header-navs/HeaderNavs"
import { Col, Row } from "antd"

const CardsFullWidth: FC<{
  cards: Maybe<SectionCardsFragment>
  children?: React.ReactNode
  isWithColumns?: boolean
  maxWidth?: boolean
  isStaticArrow?: boolean
  titleLink?: boolean
}> = ({ cards, isWithColumns, children, maxWidth, titleLink }) => {
  const { setOpenKeys } = useGeneralContext()
  return (
    <section className={`wrapperCardsFullWidth ${maxWidth && "maxWidth"} containerWrapper`}>
      <div className={"container"}>
        {cards?.title && !cards?.sup && <h1 className={"title"}>{cards?.title}</h1>}
        {cards?.title && titleLink && cards?.sup && (
          <NavLink
            className={`linkTitle ${!cards?.sup || (cards?.sup === homePageLink && "emptyLink")}`}
            to={cards?.sup}
            onClick={() => setOpenKeys([])}
          >
            <h1 className={"title"}>{cards?.title}</h1>
          </NavLink>
        )}
        {cards?.text && <h6 className={"text lightWeight"}>{cards?.text}</h6>}
          <ul className={"links"} style={{width: "100%"}}>
            <Row gutter={[60, 8]}>
              {cards?.cards?.map((link) => (
                <Col key={link?.title} span={isWithColumns ? 12 : 24}>
                  <li style={{ width: "100%" }}>
                    <HeaderNavs title={link?.title as string} text={link?.text ?? ''} imgURL={link?.icon?.data?.attributes?.url as string}
                                href={link?.url as string} />
                  </li>
                </Col>
              ))}
            </Row>

          </ul>
      </div>
      {children}
    </section>
  )
}

export { CardsFullWidth }
