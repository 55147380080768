import { FC } from "react"
import "./style.less"

const IconsSection: FC<ListLinksFragment> = ({ title, links }) => {
  return (
    <section className={"wrapperIconsSection"}>
      {title && (
        <span style={{ fontSize: 14, color: "#2e2e3a" }} className={"title textMedium"}>
          {title}
        </span>
      )}
      {!!links?.length && (
        <ul className={"links"}>
          {links.map((link, index) => (
            <li key={index}>
              <a href={link?.url} style={{ padding: 0 }}>
                <img
                  src={link?.icon?.data?.attributes?.url}
                  alt={""}
                  style={{ width: "100%", minWidth: 36, height: "auto", minHeight: 36, objectFit: "cover" }}
                />
              </a>
            </li>
          ))}
        </ul>
      )}
    </section>
  )
}

export { IconsSection }
