import { FC } from "react"
import { NavLink } from "react-router-dom"
import { homePageLink } from "../../constants"
import { SvgIcon } from "../../ui/SvgIcon"
import "./style.less"

const Logo: FC = () => {
  return (
    <NavLink to={homePageLink}>
      <SvgIcon type={"Logo"} className={"logo"} />
    </NavLink>
  )
}

export { Logo }
