import { Spin } from "antd"
import React, { FC, MutableRefObject } from "react"

const Loader: FC<{ loading?: boolean; refLoading?: MutableRefObject<null>; spinning?: boolean }> = ({
  loading,
  spinning,
}) => {
  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size={"large"} className={"app-loader"} spinning={spinning ? spinning : loading} />
      </div>
    </React.Fragment>
  )
}

export { Loader }
