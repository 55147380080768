import { Col, Divider, Row } from "antd"
import { marked } from "marked"
import { FC } from "react"
import { SvgIcon } from "../../ui/SvgIcon"
import "./style.less"
const AddressLinkSection: FC<{ sections: Maybe<TitleTextFragment>[] }> = () => {
  const address = `4580 Weaver Parkway, Suite 202


Warrenville, IL 60555


United States
`
  return (
    <section className={"wrapperAddressLinkSection"}>
      <div style={{ marginBottom: 16, textTransform: "uppercase", color: "rgba(124, 124, 131, 0.5)" }}>
        <span style={{ color: "#2E2E3A" }}>Chicago (HQ)</span>
        <Divider type={"vertical"} />
        Canada
        <Divider type={"vertical"} />
        India
      </div>
      <Row gutter={8} wrap={false} style={{ marginBottom: 12 }}>
        <Col>
          <SvgIcon type={"Home"} />
        </Col>
        <Col
          className={"contact-details"}
          dangerouslySetInnerHTML={{
            __html: `${marked.parse(address as string)}`,
          }}
        />
      </Row>
      <a href={"mailto:contact@xcelpros.com"}>
        <Row gutter={8} wrap={false} style={{ marginBottom: 12 }}>
          <Col>
            <SvgIcon type={"Letter"} />
          </Col>
          <Col className={"contact-details"}>contact@xcelpros.com</Col>
        </Row>
      </a>
      <a href={"tel:6308690900"}>
        <Row gutter={8} wrap={false}>
          <Col>
            <SvgIcon type={"Phone"} />
          </Col>
          <Col className={"contact-details"}>630-869-0900</Col>
        </Row>
      </a>
    </section>
  )
}

export { AddressLinkSection }
