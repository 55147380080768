import React, { FC } from "react"
import { truncateWithEllipsis } from "../../utils"
import { Img } from "../Img"

const ImgLinkHeader: FC<{
  img: Maybe<{ data?: Maybe<{ attributes?: Maybe<{ url: string; alternativeText?: Maybe<string> }> }> }>
  title: Maybe<string>
}> = ({ img, title }) => {
  return (
    <React.Fragment>
      <div className={"wrapperImgHeader"}>
        <Img img={img} className={"img"} />
      </div>
      {title && <h6 className={"titleCard"}>{truncateWithEllipsis(title, 35)}</h6>}
    </React.Fragment>
  )
}

export { ImgLinkHeader }
