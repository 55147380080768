import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { ConfigProvider } from "antd/es"
import { memo, type FC } from "react"
import { HelmetProvider } from "react-helmet-async"
import introspection from "../../graphql"
import Pages from "../../pages"
import theme from "../../themes"
import { ContextProvider } from "../context"

const client = new ApolloClient({
  link: createHttpLink({
    uri: `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}`,
    credentials: "same-origin",
  }),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

const App: FC = memo(() => (
  <ApolloProvider client={client}>
    <ContextProvider>
      <ConfigProvider theme={theme}>
        <HelmetProvider>
          <Pages />
        </HelmetProvider>
      </ConfigProvider>
    </ContextProvider>
  </ApolloProvider>
))

export default App
