import { Col, Row } from "antd"
import { FC } from "react"
import { Card } from "."
import { CardsFullWidth } from "../../section"
import { useMenuTop } from "../../utils"
import "./style.less"

interface Props {
  card: Maybe<CardFragment>
  sectionsCards: Maybe<Maybe<SectionCardsFragment>[]>
  cardsOpenItem: Maybe<Maybe<ListLinksFragment>[]>
}

const MainSectionListOpeningCardRight: FC<Props> = ({ card, sectionsCards }) => {
  useMenuTop()
  return (
    <section className={"wrapperMainSection layout wrapperMainSectionRight"} onClick={e => e.stopPropagation()}>
      <Card card={card} />
      {!!sectionsCards?.length && (
        <div className={"wrapper"}>
          <div className={"cards"}>
            <Row gutter={20}>
              <Col span={16}>
                <CardsFullWidth cards={sectionsCards[0]} isWithColumns={true} titleLink />
              </Col>
              <Col span={8}>
                <CardsFullWidth cards={sectionsCards[1]} titleLink />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </section>
  )
}

export { MainSectionListOpeningCardRight }
