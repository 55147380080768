import useDetectScroll from "@smakss/react-scroll-direction"
import classnames from "classnames"
import { FC, useEffect, useMemo, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { useLocation } from "react-router-dom"
import Alert from "../ui/Alert/index"
import {
  changePoint,
  homePageLink,
  isTabletValue,
  pathBlog,
  pathCategory,
  pathChallenges,
  pathCustomerJourneys,
  pathIndustries,
  pathProducts,
  pathSlides,
  pathTag,
} from "../constants"
import { AuthorizationLinks, DesktopMenu, TabletMenu } from "./headerSection"
import "./headerSection/style.less"

const Header: FC<{ isDarkTheme: boolean }> = ({ isDarkTheme }) => {
   const [showAlert, setShowAlert] = useState(true)
   const [alertMessage, setAlertMessage] = useState("This is xcelpros alert banner!")
  const isTablet = useMediaQuery(isTabletValue)
  const { pathname } = useLocation()
 const handleCloseAlert = () => {
   setShowAlert(false)
 }
  const [isWhiteHeader, setIsWhiteHeader] = useState(pathname === homePageLink && !isTablet)

  const { scrollDir, scrollPosition } = useDetectScroll()

  useEffect(() => {
    const isHomePage = pathname === homePageLink
    setIsWhiteHeader(isHomePage && !isTablet)
  }, [pathname, isTablet])

  const splitPathname = pathname.split("/")
  const pageWithOpacityHeader =
    [
      pathBlog,
      pathChallenges,
      pathCustomerJourneys,
      pathSlides,
      pathIndustries,
      pathTag,
      pathCategory,
      pathProducts,
    ].includes(splitPathname[1]) && splitPathname[2]

  const headerClassNames = useMemo(
    () =>
      classnames({
        "app-header": true,
        // "bckLightBlackWhite": isDarkTheme,
        "bckBlue": !isDarkTheme,
        "opacityHeader": pageWithOpacityHeader && scrollPosition.top < 140,
        "whiteHeader": isWhiteHeader,
        "bckWhite": isWhiteHeader,
        "header-hidden": scrollPosition.top > changePoint && scrollDir === "down",
        "header-fixed-visible": scrollPosition.top > changePoint && scrollDir === "up",
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrollPosition.top, scrollDir, isDarkTheme, isWhiteHeader, pathname]
  )

  return (
    <header className={headerClassNames}>
      {showAlert && <Alert message={alertMessage} onClose={handleCloseAlert} />}
      <AuthorizationLinks isTablet={isTablet} isDarkTheme={isDarkTheme} isWhiteHeader={isWhiteHeader} />
      <div className={"wrapperMenu"}>{isTablet ? <TabletMenu pathname={pathname} /> : <DesktopMenu />}</div>
    </header>
  )
}

export { Header }
