import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      activeBarHeight: 0,
      colorPrimaryBg: "unset",
      fontSize: 18,
      motionDurationSlow: "0.6s",
      motionDurationMid: "0.6s",
    },
    Layout: {
      colorBgLayout: "unset",
      headerBg: "unset",
      bodyBg: "unset",
    },
    Radio: {
      radioSize: 28,
      dotSize: 20,
      buttonCheckedColorDisabled: "rgba(46,46,58, 0.4)",
    },
    Button: {
      defaultBg: "transparent",
      defaultHoverBg: "#2e2e3a",
      defaultHoverBorderColor: "#2e2e3a",
      defaultHoverColor: "#ffffff",
    },
    Tabs: {
      inkBarColor: "#2E2E3A",
      itemColor: "rgba(124, 124, 131, 0.5)",
      itemHoverColor: "#2E2E3A",
      itemSelectedColor: "#2E2E3A",
    },
  },
}

export default theme
