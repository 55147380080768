import { Col, Row } from "antd"
import { FC, useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import "./HeaderNavs.less"
import { useGeneralContext } from "../../../context"

const HeaderNavs: FC<{ title: string; text?: string; imgURL: string | undefined; href: string }> = ({
  title,
  text,
  imgURL,
  href
}) => {
  const [svg, setSvg] = useState<any>(null)
  const {setOpenKeys} = useGeneralContext()
  const [isLoaded, setIsLoaded] = useState(false)
  const [isErrored, setIsErrored] = useState(false)

  const splitURL = imgURL?.split(".")
  const isPNG = splitURL?.[splitURL?.length - 1] === "png"

  useEffect(() => {
    fetch(imgURL ?? '')
      .then(res => res.text())
      .then(res => setSvg(res))
      .catch(setIsErrored)
      .then(() => setIsLoaded(true))

    console.log(svg)
  }, [imgURL])
console.log(isPNG)
  return (
    <NavLink to={href} className={"header-navigation"} onClick={() => setOpenKeys([])}>
      <Row gutter={14} style={{ width: "100%" }} wrap={false}>
        <Col>
          <div className={"header-nav-icon"} dangerouslySetInnerHTML={{ __html: isPNG ? `<img src=${imgURL} alt=${title}/>` : svg }} />
        </Col>
        <Col flex={1}>
          <div className={"link-title"}>{title}</div>
          <div className={"link-text"}>{text}</div>
        </Col>
      </Row>
    </NavLink>
  )
}

export { HeaderNavs }
