import { Divider } from "antd"
import { format } from "date-fns"
import React, { FC } from "react"
import { NavLink } from "react-router-dom"
import { useFooterQuery } from "../../graphql"
import { homePageLink } from "../constants"
import { SvgIcon } from "../ui/SvgIcon"
import { AddressLinkSection, IconsSection, LinkSection, OthersLinksSection } from "./footerSection"
import { EmailSubscriptionForm } from "./footerSection/email-subscription"


const Footer: FC = () => {
  const { data: footerData } = useFooterQuery()
  const data = footerData?.footer?.data?.attributes

  const propsLinkSection = []
  data?.linkServices &&
  data?.listServices &&
  propsLinkSection.push({ link: data.linkServices, list: data.listServices })
  data?.linkProducts?.title &&
  data?.listProducts &&
  propsLinkSection.push({ link: data.linkProducts, list: data.listProducts })
  data?.linkIndustries?.title &&
  data?.listIndustries &&
  propsLinkSection.push({ link: data.linkIndustries, list: data.listIndustries })
  data?.linkInsights?.title &&
  data?.listInsights &&
  propsLinkSection.push({ link: data.linkInsights, list: data.listInsights })
  data?.linkAboutUs?.title &&
  data?.listAboutUs &&
  propsLinkSection.push({ link: data.linkAboutUs, list: data.listAboutUs })

  return (
    <React.Fragment> { data && <footer className={"wrapperFooter"}>
      <div className={"container"}>
        <NavLink to={homePageLink}>
          <SvgIcon type={"Logo"} className={"logo"} />
        </NavLink>
        <Divider style={{ height: 2, color: "#7c7c83", margin: 0 }} />
        <div className={"wrapperMain"}>
          {!!propsLinkSection.length && <LinkSection propsLinkSection={propsLinkSection} />}
          <div className={"wrapperSub"}>
            {data?.iconsSection && <IconsSection {...data.iconsSection} />}
            {!!data?.listAddress?.length && <AddressLinkSection sections={data?.listAddress} />}
            <EmailSubscriptionForm />
          </div>
        </div>
        {!!data?.otherListLinks?.links?.length && <OthersLinksSection links={data?.otherListLinks?.links} />}
        <Divider style={{ height: 2, color: "#7c7c83" }} />
        <div style={{ fontSize: 14, color: "#7c7c83", marginBottom: 30 }}>{`XcelPros ©${format(
          new Date(),
          "yyyy"
        )}`}</div>
      </div>
    </footer>
    }
    </React.Fragment>


)
}

export { Footer }
