import { FC,lazy,useEffect,useState } from "react"
import { BrowserRouter,Route,Routes,ScrollRestoration } from "react-router-dom"
import {
pathBlog,
pathBrochure,
pathCareers,
pathCategory,
pathChallenges,
pathCustomerJourneys,
pathIndustries,
pathProducts,
pathSearch,
pathServices,
pathSlides,
pathTag,
pathWhitepaper,
} from "../components/constants"
import DefaultLayout from "../components/layout"
import Meta from "../components/seo/Meta"
import { MetaProvider } from "../components/seo/MetaContext"
import { useWebsiteQuery } from "../graphql"
import EventDetailPage from "./event-details"

const Home = lazy<FC>(() => import("./home"))
const WhyXCelPros = lazy<FC>(() => import("./whyXcelPros"))
const FAQ = lazy<FC>(() => import("./faq"))
const ContactUs = lazy<FC>(() => import("./contactUs"))
const Cookies = lazy<FC>(() => import("./cookie"))
const Sitemap = lazy<FC>(() => import("./sitemap"))
const About = lazy<FC>(() => import("./about"))
const Eula = lazy<FC>(() => import("./eula"))
const Culture = lazy<FC>(() => import("./culture"))
const MicrosoftSoftwarePackages = lazy<FC>(() => import("./servicePackagesOffers"))
const Privacy = lazy<FC>(() => import("./privacyPolicy"))
const News = lazy<FC>(() => import("./news"))
const Career = lazy<FC>(() => import("./career"))
const Careers = lazy<FC>(() => import("./careers"))
const Blog = lazy<FC>(() => import("./blog"))
const BlogPost = lazy<FC>(() => import("./blogPost"))
const Brochure = lazy<FC>(() => import("./brochure"))
const Whitepaper = lazy<FC>(() => import("./whitepaper"))
const Challenge = lazy<FC>(() => import("./challenge"))
const ChallengePost = lazy<FC>(() => import("./challengePost"))
const CustomerJourney = lazy<FC>(() => import("./customerJourney"))
const CustomerJourneyPost = lazy<FC>(() => import("./customerJourneyPost"))
const Slide = lazy<FC>(() => import("./slide"))
const SlidePost = lazy<FC>(() => import("./slidePost"))
const Insight = lazy(() => import("./insight"))
const Industry = lazy<FC>(() => import("./industry"))
const Industries = lazy<FC>(() => import("./industries"))
const Product = lazy<FC>(() => import("./product"))
const Products = lazy<FC>(() => import("./products"))
const Service = lazy<FC>(() => import("./service"))
const Services = lazy<FC>(() => import("./services"))
const NotFound = lazy<FC>(() => import("./not-found"))
const Tag = lazy<FC>(() => import("./tag"))
const Category = lazy<FC>(() => import("./category"))
const ScheduleMeetingPage = lazy<FC>(() => import("./scheduleMeeting"))
const SearchPage = lazy<FC>(() => import("./search"))
const EventPage = lazy<FC>(() => import("./eventsd"))
const Event = lazy<FC>(() => import("./event"))
const WriteForUs = lazy<FC>(() => import("./writeForUs"))

const App = () => {
  return (
    <Routes>
      <Route path={"/"} element={<DefaultLayout />}>
        <Route index={true} element={<Home />} />
        <Route path={"/why-xcelpros"} element={<WhyXCelPros />} />
        <Route path={"/faq"} element={<FAQ />} />
        <Route path={`/${pathSearch}`} element={<SearchPage />} />
        <Route path={"/contact"} element={<ContactUs />} />
        <Route path={"/our-sitemap"} element={<Sitemap />} />
        <Route path={"/cookies"} element={<Cookies />} />
        <Route path={"/about"} element={<About />} />
        <Route path={`/write-for-us`} element={<WriteForUs />} />
        <Route path={"/eula"} element={<Eula />} />
        <Route path={"/culture"} element={<Culture />} />
        <Route path={"/microsoft-software-packages"} element={<MicrosoftSoftwarePackages />} />
        <Route path={"/privacy-policy"} element={<Privacy />} />
        <Route path={"/news"} element={<News />} />
        <Route path={`/${pathCareers}`} element={<Career />} />
        <Route path={`/${pathCareers}/:careerName`} element={<Careers />} />
        <Route path={`/${pathBlog}`} element={<Blog />} />
        <Route path={`/${pathBlog}/:blogSlug`} element={<BlogPost />} />
        <Route path={`/${pathChallenges}`} element={<Challenge />} />
        <Route path={`/${pathChallenges}/:challengeSlug`} element={<ChallengePost />} />
        <Route path={`/${pathCustomerJourneys}`} element={<CustomerJourney />} />
        <Route path={`/${pathCustomerJourneys}/:customerJourneysSlug`} element={<CustomerJourneyPost />} />
        <Route path={`/${pathBrochure}`} element={<Brochure />} />
        <Route path={`/${pathWhitepaper}`} element={<Whitepaper />} />
        <Route path={`/${pathSlides}`} element={<Slide />} />
        <Route path={`/${pathSlides}/:slideSlug`} element={<SlidePost />} />
        <Route path={"/insight"} element={<Insight />} />
        <Route path={`/${pathIndustries}`} element={<Industry />} />
        <Route path={`/${pathIndustries}/:industryName`} element={<Industries />} />
        <Route path={`/${pathProducts}`} element={<Product />} />
        <Route path={`/${pathProducts}/:productName`} element={<Products />} />
        <Route path={`/${pathServices}`} element={<Service />} />
        <Route path={`/${pathServices}/:serviceName`} element={<Services />} />
        <Route path={`/${pathTag}/:tagSlug`} element={<Tag />} />
        <Route path={`/${pathCategory}/:categorySlug`} element={<Category />} />
        <Route path={`/schedule-meeting`} element={<ScheduleMeetingPage />} />
        <Route path={`/eventsd`} element={<EventPage />} />
        <Route path={`/eventsd/:Slug`} element={<EventDetailPage />} />
        <Route path={`/event/:eventSlug`} element={<Event />} />
        <Route path={"*"} element={<NotFound />} />
      </Route>
    </Routes>
  )
}

const useWebsite: () => Maybe<SeoFragment> = () => {
  const { data, loading } = useWebsiteQuery()
  const [seo, setSeo] = useState(data?.website?.data?.attributes?.seo)
  useEffect(() => {
    !loading && data?.website?.data?.attributes?.seo && setSeo(data?.website?.data?.attributes?.seo)
  }, [data?.website?.data?.attributes?.seo, loading])
  return seo
}

function withMeta<T>(Wrapper: FC<T>): FC<T> {
  return props => (
    <MetaProvider>
      <Meta />
      <Wrapper {...props}>
        <ScrollRestoration />
      </Wrapper>
    </MetaProvider>
  )
}

const Pages = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

export { useWebsite }
export default withMeta(Pages)
