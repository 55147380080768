import { Dispatch, FC, SetStateAction } from "react"
import vectorImgCard from "../../../images/vector-card-menu.png"
import { homePageLink } from "../../constants"
import { useGeneralContext } from "../../context"
import { Img } from "../../ui/Img"
import { Link } from "../../ui/Link"
import "./style.less"

const Card: FC<{
  card: Maybe<CardFragment>
  staticImg?: boolean
  setOpenKeysBurger?: Dispatch<SetStateAction<string[]>>
}> = ({ card, staticImg, setOpenKeysBurger }) => {
  const { setOpenKeys } = useGeneralContext()
  return (
    <article className={"wrapperCard bckLightBlackWhite radius"}>
      {card?.title && <h1 className={"title"}>{card?.title}</h1>}
      {staticImg ? (
        <img src={vectorImgCard} alt={"vector"} className={"img"} />
      ) : (
        <Img img={card?.icon} className={"img"} />
      )}
      {card?.text && (
        <p className={`avgTextLightBookMedium text ${card?.icon?.data?.attributes?.url && "textWithImg"}`}>
          {card?.text}
        </p>
      )}
      <Link
        link={{ url: card?.url, title: card?.sup }}
        className={`linkBtn bigLinkThird radius ${!card?.url || (card?.url === homePageLink && "emptyLink")}`}
        onClick={() => (setOpenKeysBurger ? setOpenKeysBurger([]) : setOpenKeys([]))}
      />
    </article>
  )
}

export { Card }
