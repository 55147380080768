import { Menu, MenuProps } from "antd"
import { FC, useEffect } from "react"
import { NavLink } from "react-router-dom"
import {
  Logo,
  MainSectionCardsList,
  MainSectionListOpeningCardCenter,
  MainSectionListOpeningCardRight,
  addItemMenu,
} from "."
import { useHeaderQuery } from "../../../graphql"
import { changePoint, pathSearch } from "../../constants"
import { useGeneralContext } from "../../context"
import { SvgIcon } from "../../ui/SvgIcon"
import "./style.less"
import { InsightsSubMenu } from "./insights-submenu/InsightsSubMenu"
import useDetectScroll from "@smakss/react-scroll-direction"

const DesktopMenu: FC = () => {
  const { data: headerData, loading } = useHeaderQuery()
  const { openKeys, setOpenKeys } = useGeneralContext()
  const data = headerData?.header?.data?.attributes
  const items: MenuProps["items"] = []
  const { scrollDir, scrollPosition } = useDetectScroll()

  useEffect(() => {
    scrollPosition.top > changePoint && scrollDir === "down" && setOpenKeys([])
  }, [scrollPosition, scrollDir])
  const onOpenChange: MenuProps["onOpenChange"] = keys => setOpenKeys(keys)
  if (loading) {
    return null
  }
  items?.push(
    addItemMenu(
      data?.firstMenuTitle,
      <MainSectionListOpeningCardCenter
        card={data?.firstMenuCard}
        sectionsCards={data?.firstMenuSectionsCards}
        cardsOpenItem={data?.firstMenuSectionsCardsOpenItem}
      />
    ),
    addItemMenu(
      data?.secondMenuTitle,
      <MainSectionListOpeningCardRight
        card={data?.secondMenuCard}
        sectionsCards={data?.secondMenuSectionsCards}
        cardsOpenItem={data?.secondMenuSectionsCardsOpenItem}
      />
    ),
    addItemMenu(data?.whyXcelPros),
    addItemMenu(
      data?.thirdMenuTitle,
      <MainSectionCardsList card={data?.thirdMenuCard} sectionsCards={data?.thirdMenuSectionsCards} />
    ),
    addItemMenu(
      data?.fourthMenuTitle,
      <InsightsSubMenu />
    ),
    addItemMenu(data?.fifthMenuTitle)
    //the section that was in the initial design, maybe we will use it in the future
    // addItemMenu(
    //   data?.fifthMenuTitle,
    //   <MainSectionCardsList card={data?.fifthMenuCard} sectionsCards={data?.fifthMenuSectionsCards} />
    // )
  )

  return (
    <div className={"container desktopMenu"}>
      <div className={"borderWrapper"}>
        <Logo />
        <Menu
          mode={"horizontal"}
          items={items}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        />
        <NavLink to={`/${pathSearch}`} className={"link linkIcon"}>
          <SvgIcon type={"Search"} className={"icon"} />
        </NavLink>
      </div>
    </div>
  )
}

export { DesktopMenu }
