import { Card, Col, Divider, Row } from "antd/es"
import Meta from "antd/es/card/Meta"
import { format } from "date-fns"
import React, { FC } from "react"
import { Link } from "react-router-dom"
import { readingTime } from 'reading-time-estimator'
import { useTrendingBlogPostCardQuery } from "src/graphql"
import "./InsightsSubMenu.less"
import { SvgIcon } from "../../../ui/SvgIcon"

const InsightsSubMenu: FC = () => {
  const { data } = useTrendingBlogPostCardQuery()
  return (
    <React.Fragment>
      <div className={"submenu-header"}>Featured Insights</div>
      <Row gutter={18}>
        {data&& data?.blogPosts?.data?.slice(0, 3)?.map(post => {
          return <Col span={6} key={post?.attributes?.title}>
            <Link to={`/blog/${post?.attributes?.slug}` ?? ''}>
              <Card
                style={{ width: "100%" }}
                className={'trending-card'}
                cover={
                  <img
                    alt={post?.attributes?.title ?? ''}
                    src={post?.attributes?.cover?.data?.attributes?.url ?? ''}
                  />
                }
              >
                <Row className={'tags'} gutter={8}>
                  <Col>
                    <div className={"tag"}>Blog</div>
                  </Col>
                  {post?.attributes?.gated && <Col>
                    <div className={"tag"}>Premium</div>
                  </Col>}
                </Row>
                <Meta
                  title={post?.attributes?.title}
                />
                <div className={'post-details'}>
                  <span>{format(post?.attributes?.createdAt ?? new Date(), "MMM dd, yyyy")}</span>
                  <span><Divider type={"vertical"} style={{borderColor: "#5C5C5C"}}/></span>
                  <span>
                   {post?.attributes?.content && <Row gutter={8}>
                      <Col><SvgIcon type={"Clock"}/></Col>
                       <Col>{readingTime(post?.attributes?.content as string, 300).text}</Col>
                    </Row> }
                  </span>
                </div>
              </Card>
            </Link>
          </Col>
        })}
        <Col span={6}>
          <Row className={'nav-buttons'} gutter={[16, 16]}>
            <Col span={24}>
              <Link className={"nav-button blogs"} to={"/blog"}>Go to Blogs</Link>
            </Col>
            <Col span={24}>
              <Link className={"nav-button slides"} to={"/brochure"}>Go to Brochures</Link>
            </Col>
            <Col span={24}>
              <Link className={"nav-button whitepapers"} to={"/challenges"}>Go to Challenges</Link>
            </Col>
            <Col span={24}>
              <Link className={"nav-button customer-journeys"} to={"/customer-journeys"}>Go to Customer Journeys</Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export { InsightsSubMenu }
