import { FC } from "react"

const Button: FC<{ btn: string; className: string }> = ({ btn, className }) => {
  return (
    <button className={`btn ${className}`} type={"submit"}>
      <span className={"textBtn"}>{btn}</span>
      <span className={"arrowIcon"} />
    </button>
  )
}

export { Button }
